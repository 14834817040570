import { ControllerFlowAPI } from '@wix/yoshi-flow-editor-runtime/build/cjs';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { GroupsApi } from '../../../services';
import { IntegrationData, RestrictedProps } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { Router } from './Router';

export class RestrictedController {
  constructor(
    protected setProps: (props: Partial<RestrictedProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected router: Router,
    protected groupsApi: GroupsApi,
    protected flowAPI: ControllerFlowAPI,
  ) {}

  public async initialize(pageId: string, integrationData: IntegrationData) {
    return this.update(pageId, integrationData);
  }

  public async update(pageId: string, integrationData: IntegrationData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.RestrictedPageLoaded);
    const missingPlanIds = await this.groupsApi.getMissingPlanIds(pageId);
    const missingPlan = missingPlanIds.length > 0;
    this.setProps({
      missingPlan,
      switchAccounts: this.logout,
      navigateBackToPlanList: () =>
        this.goToPlanList({
          ...integrationData,
          restrictedPageId: undefined,
          planIds: missingPlan ? missingPlanIds : undefined,
        }),
    });
  }

  logout = () => this.wixCodeApi.user.logout();

  goToPlanList = (integrationData: IntegrationData) => this.router.gotoList(integrationData);
}
