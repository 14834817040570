import { MembershipApi } from '@wix/ambassador-membership-api/http';
import { ApiHeaders } from './headers';

type MembershipApiT = ReturnType<typeof MembershipApi>;
export type PremiumService = ReturnType<MembershipApiT['PremiumService']>;

export class PremiumApi {
  constructor(protected headers: ApiHeaders, protected premiumService: PremiumService) {}

  async getPremiumMessageDisplayStatus() {
    const res = await this.getPremiumStatus();
    return !res?.acceptPaymentsEnabled && !res?.hasOldPlans;
  }

  getPremiumStatus() {
    return this.premiumService(this.headers).getPremiumStatus({});
  }

  async shouldUpgrade() {
    const { acceptPaymentsEnabled, acceptPaymentsRequired } = await this.getPremiumStatus();
    return acceptPaymentsRequired && !acceptPaymentsEnabled;
  }
}

export function createPremiumApi(headers: ApiHeaders, baseUrl: string = '') {
  return new PremiumApi(headers, MembershipApi(baseUrl + '/_api/paid-plans').PremiumService());
}
