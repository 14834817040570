import {
  PricingPlansReadApi,
  ListPublicPlansRequest,
  PublicPlan,
  QueryPublicPlansRequest,
} from '@wix/ambassador-pricing-plans-read-api/http';
import isEqual from 'lodash/isEqual';

import { PLANS_QUERY_PAGE_SIZE } from '../constants';
import { ApiHeaders } from './headers';
import { wrapMethods } from '../utils/object';
import { memoize } from '../utils/fp';

type PricingPlansReadApiT = ReturnType<typeof PricingPlansReadApi>;
type PlansService = ReturnType<PricingPlansReadApiT['PlansService']>;

export function createPlansApi(headers: ApiHeaders, baseUrl = '') {
  return new PlansApi(headers, PricingPlansReadApi(baseUrl + '/_api/paid-plans').PlansService());
}

export function memoizePlansApi(api: PlansApi, compare: (a: any[], b: any[]) => boolean = isEqual): PlansApi {
  return wrapMethods(api, (o, name) => memoize<Promise<PublicPlan[]>>(o[name].bind(o), compare));
}

type Filter = { exclude: string[] } | { include: string[] };
export type PlansQuery = Filter & { page?: number };

export class PlansApi {
  constructor(protected headers: ApiHeaders, protected plansService: PlansService) {}

  public async loadPaidPlans(request: ListPublicPlansRequest = {}): Promise<PublicPlan[]> {
    const { plans } = await this.plansService(this.headers).listPublicPlans(request);
    return plans || [];
  }

  public async query(query: Partial<PlansQuery> = {}): Promise<PublicPlan[]> {
    const { plans } = await this.plansService(this.headers).queryPublicPlans(queryToRequest({ exclude: [], ...query }));
    return plans || [];
  }
}

function queryToRequest(query: PlansQuery): QueryPublicPlansRequest {
  return {
    limit: PLANS_QUERY_PAGE_SIZE,
    offset: query.page ? query.page * PLANS_QUERY_PAGE_SIZE : undefined,
    filter: queryToFilter(query),
  };
}

function queryToFilter(query: Filter): QueryPublicPlansRequest['filter'] {
  if ('exclude' in query && query.exclude.length > 0) {
    return { $not: { id: { $in: query.exclude } } };
  }
  if ('include' in query && query.include.length > 0) {
    return { id: { $in: query.include } };
  }
}
